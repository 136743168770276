import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";

import { Card, CardContent, CardMedia, Typography } from "@material-ui/core";

import { observer } from "mobx-react-lite";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Slider from "@material-ui/core/Slider";
import CardHeader from "@material-ui/core/CardHeader";

import Chip from "@material-ui/core/Chip";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import {isInPeriode} from './utils';

import "./wishlist.css";

function WhishListCGItem({ appStore, item }) {
  return (
    
      <Paper className="root">
        <div className="cover-md">
          <img className="item-image" src={item.imageName[0]}></img>
        </div>
        <div className="details width100">
          <div className="content">

            <div className="cover-sm">
              <img className="item-image" src={item.imageName[0]}></img>
            </div>

            <div className="grow">
              <Typography gutterBottom variant="h5" component="h4">
                {item.itemName}
              </Typography>

              <Typography
                variant="subtitle1"
                color="textSecondary"
                component="p"
              >
                {item.price} CHF{" "}
              </Typography>
            </div>

            <div className="chipsContainer">
              <div className="slider">
                <Slider
                   disabled={!isInPeriode(new Date())}
                  defaultValue={appStore.getCeeGees(item.id)}
                  onChange={(e, v) => {
                    appStore.assignCeeGees(item.id, v);
                  }}
                  valueLabelDisplay="auto"
                  valueLabelDisplay="on"
                  min={0}
                  max={100}
                />
              </div>

              <div className="chipContainer bottom grow"></div>

              <div className="chipContainer bottom">
                <Chip
                  disabled={!isInPeriode(new Date())}
                  icon={<HighlightOffIcon />}
                  className="actionChip"
                  label="Move to furniture list"
                  clickable
                  color="primary"
                  onClick={(e) => appStore.deleteFromWishList(item.id)}
                />
              </div>
            </div>
          </div>
        </div>
      </Paper>
    
  );
}
export default observer(WhishListCGItem);
