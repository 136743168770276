import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import Grid from "@material-ui/core/Grid";
import { IconButton } from "@material-ui/core";
import CGItem from "./item";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { makeStyles } from "@material-ui/core/styles";
import { toJS } from "mobx";
import Typography from "@material-ui/core/Typography";
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import "./inventory.css";
import {isInPeriode} from './utils';

const useStyles = makeStyles((theme) => ({
  selected: {
    color: "#f50057",
  },
  chipContainer: {
    padding:"5px",
    width:"100%"
  },
  chip: {
    width:"100%",
    color:"#1e5aa1"
  }
}));

function Inventory({ appStore }) {
  const classes = useStyles();

  useEffect(() => {
    if (appStore.cgItems == null || appStore.cgItems.length == 0) {
      appStore.fetchCGItems();
    }
  });

  const countColumns = () => {
    let colonneLength = Math.floor(appStore.cgItems.length / 4);
    let remaining = appStore.cgItems.length % 4;

    return {
      colonneLength: colonneLength,
      remaining: remaining,
    };
  };

  const addRemaining = (i, remaining) => remaining > i-1 ? 1 : 0

  const calculateEnd = (start, colonneLength, col, remaining) => start + colonneLength + addRemaining(col, remaining)

  const generateItems = () => {
    let columns = {};

    let { colonneLength, remaining } = countColumns();

    if (colonneLength == 0) return columns;


    let start = 0;


    for (let col = 1; col <= 4; col++) {
      if (columns[col] == null) columns[col] = [];

      let end = calculateEnd(start, colonneLength, col, remaining);

      for (
        let i = start;
        i < end;
        i++
      ) {
        let itemData = appStore.cgItems[i];

        columns[col].push(
          <CGItem
            key={itemData.id}
            imageName={itemData.imageName}
            itemName={itemData.itemName}
            price={itemData.price}
            description={itemData.description}
            itemId={itemData.id}
            appStore={appStore}
          >
            {appStore.isCGItemCommitted(itemData.id) ? (
              <div className={classes.chipContainer}>
                <Chip className={classes.chip} label={"CeeGees assigned: " + appStore.getCeeGees(itemData.id)} color="primary" variant="outlined"  />
              </div>
            ) : (
              <IconButton
                disabled={!isInPeriode(new Date())}
                onClick={(e) => {
                  if (appStore.isCGItemWished(itemData.id)) {
                    appStore.deleteFromWishList(itemData.id);
                  } else {
                    appStore.addToWishList(itemData.id);
                  }
                }}
                aria-label="add to wishlist"
                className={
                  appStore.isCGItemWished(itemData.id) ? classes.selected : ""
                }
              >
                <FavoriteIcon />
              </IconButton>
            )}
          </CGItem>
        );

        start = start + 1;
      }
    }

    return columns;
  };

  let items = generateItems();
  return (
    <div className="inventory-container">
      {Object.keys(items).map((col) => (
        <div key={col} className="inventory-column">{items[col]}</div>
      ))}
    </div>
  );
}

export default observer(Inventory);
