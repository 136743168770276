import React from "react";
import Grid from "@material-ui/core/Grid";
import { observer } from "mobx-react-lite";
import WhishListCGItem from "./wishlist-item";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import { toJS } from "mobx";
import { useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";
import {isInPeriode} from './utils';
const useStyles = makeStyles((theme) => ({
  right: {
    float: "right",
  },

  commitMargin: {
    margin: theme.spacing(1),
  },

  divider: {
    marginTop: theme.spacing(2),
  },
}));

function CGWhishList({ appStore }) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div>
      <Grid container spacing={3}>
        {appStore.getWishedCGItemBaskets.length > 0 &&
          appStore.getWishedCGItemBaskets
            .map((ib) => ib.cGItem)
            .map((item, index) => (
              <Grid item md={12} xs={12} key={item.id}>
                <WhishListCGItem item={item} appStore={appStore} />
              </Grid>
            ))}
        {appStore.getWishedCGItemBaskets.length == 0 && (
          <Grid item md={12} xs={12}>
            <Typography>You have no Items ...</Typography>
          </Grid>
        )}
      </Grid>

      <Divider className={classes.divider} />
      <div className={classes.right + " " + classes.commitMargin}>
        <Button
          onClick={(e) => {
            appStore.getWishedCGItemBaskets
              .filter((ib) => (ib.cgPoints == null ? 0 : ib.cgPoints) > 0)
              .forEach((ib) => appStore.commitCGItemBasket(ib));
            history.push("/inventory/commitlist");
          }}
          disabled={
            appStore.maxCeeGeesWished > 0 && appStore.maxCeeGees > -1 && isInPeriode(new Date())
              ? false
              : true
          }
          variant="contained"
          size="large"
          color="primary"
        >
          Confirm and move to basket
        </Button>
      </div>
    </div>
  );
}
export default observer(CGWhishList);
