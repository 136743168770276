import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import { Card, CardContent, CardMedia, Typography } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import { observer } from "mobx-react-lite";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import {isInPeriode} from './utils';

function CommitListCGItem({ appStore, item }) {
  const getOdds = (itemId) => {
    let array = appStore.cgBasket.cGItemBaskets.items.filter(
      (i) => i.cgItemId == itemId
    );
    if (array.length === 0) {
      return "Available tomorrow";
    } else {
      return array[0].odds != null && array[0].odds != 0
        ? array[0].odds
        : "Available tomorrow";
    }
  };

  return (
    <div>
      <Paper className="root" elevation={3}>
        <div className="cover-md">
          <img className="item-image" src={item.imageName[0]}></img>
        </div>

        <div className="details width100">
          <div className="content">
          
          <div className="cover-sm">
            <img className="item-image" src={item.imageName[0]}></img>
          </div>

            <div className="grow">
              <Typography gutterBottom variant="h5" component="h4">
                {item.itemName}
              </Typography>

              <Typography
                variant="subtitle1"
                color="textSecondary"
                component="p"
              >
                {item.price} CHF{" "}
              </Typography>
            </div>
            <div className="chipsContainer">
              <div className="chipContainer top bottom">
                <Chip
                  className="infoChip"
                  label={"CeeGees assigned: " + appStore.getCeeGees(item.id)}
                  color="primary"
                  variant="outlined"
                />
              </div>

              <div className="chipContainer bottom">
                <Chip
                  className="infoChip"
                  label={"Odds: " + getOdds(item.id)}
                  color="primary"
                  variant="outlined"
                />
              </div>

              <div className="chipContainer bottom grow"></div>

              <div className="chipContainer bottom">
                <Chip
                  disabled={!isInPeriode(new Date())}
                  icon={<HighlightOffIcon />}
                  className="actionChip"
                  label="Move to wishlist"
                  clickable
                  color="primary"
                  onClick={(e) => appStore.backToWishList(item.id)}
                />
              </div>
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
}
export default observer(CommitListCGItem);
