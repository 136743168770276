import "./App.css";
import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { observer } from "mobx-react-lite";
import Main from "./components/main";
import Landing from "./landing/landing";
import { appStore } from "./store/store";
import { createTheme, ThemeProvider, responsiveFontSizes  } from "@material-ui/core/styles";
import {AmplifyAuthenticator, AmplifySignIn} from "@aws-amplify/ui-react";
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { Hub } from "aws-amplify";

let cgTheme = createTheme({
  palette: {
    primary: {
      main: "#019ade",
    },
  },
});

cgTheme = responsiveFontSizes(cgTheme);

function App() {

  const [authState, setAuthState] = React.useState();
  const [user, setUser] = React.useState();
  
  React.useEffect(() => {
    // TODO this is a workaround when calling Auth.signOut() onAuthUIStateChange is not invoked
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signOut":
          setAuthState(AuthState.SignOut);
          setUser(null)
          break;
      }
    });
    
    return onAuthUIStateChange((nextAuthState, authData) => {
        setAuthState(nextAuthState);
        setUser(authData)
    });
}, []);

  return (
    <ThemeProvider theme={cgTheme}>
      <Router>
        <Switch>
          <Route path="/inventory">
            {authState === AuthState.SignedIn && user ? <Main appStore={appStore} /> : <AmplifyAuthenticator><AmplifySignIn slot="sign-in" hideSignUp/></AmplifyAuthenticator>  }
          </Route>
          <Route path="/">
            <Landing />
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default observer(App);
