import "../App.css";

import React, { useEffect, useState } from "react";

import { withAuthenticator, AmplifySignOut } from "@aws-amplify/ui-react";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import FavoriteIcon from "@material-ui/icons/Favorite";
import Badge from "@material-ui/core/Badge";
import { observer } from "mobx-react-lite";
import {
  AppBar,
  Container,
  IconButton,
  Toolbar,
  Typography,
} from "@material-ui/core";
import Zoom from "@material-ui/core/Zoom";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Fab from "@material-ui/core/Fab";
import HomeIcon from "@material-ui/icons/Home";
import MenuIcon from "@material-ui/icons/Menu";
import Drawer from "@material-ui/core/Drawer";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";

import Inventory from "./inventory";
import CGWhishList from "./wishlist";
import CGCommitList from "./commitlist";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import HelpIcon from "@material-ui/icons/Help";
import GroupIcon from "@material-ui/icons/Group";
import ViewListIcon from "@material-ui/icons/ViewList";
import { AmplifyAuthenticator, AmplifySignIn } from "@aws-amplify/ui-react";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import { Auth } from "aws-amplify";

import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import "./main.css";
import FAQ from "./faq"

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  title: {
    flexGrow: 1,
  },
  negativeCeeGees: {
    color: "red",
  },
  zoom: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

function ScrollTop(props) {
  const classes = useStyles();
  const trigger = useScrollTrigger();

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );
    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.zoom}>
        {props.children}
      </div>
    </Zoom>
  );
}

function Main({ appStore }) {
  const [menu, toggleMenu] = useState(false);

  const classes = useStyles();
  const history = useHistory();

  const menuNavigate = (path) => {
    history.push(path);
    toggleMenu(false);
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (!appStore.isStoreInitialize) {
          appStore.init();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  });

  function signOut() {
    Auth.signOut()
      .then(() => {
        //clear store on signout
        appStore.destroy();
        history.push("/");
      })
      .catch((e) => {
        console.log("error signing out: ", e);
      });
  }

  // wait for store to initialize
  if (!appStore.isStoreInitialize) {
    return null;
  }

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, { [classes.appBarShift]: menu })}
      >
        <Container maxWidth="lg">
          <Toolbar>
            <IconButton
              edge="start"
              className={clsx(classes.menuButton, menu && classes.hide)}
              color="inherit"
              aria-label="menu"
              onClick={(e) => toggleMenu(true)}
            >
              <MoreVertIcon />
            </IconButton>

            <Typography variant="h6" className={classes.title}></Typography>

            <IconButton
              onClick={(e) => history.push("/inventory")}
              style={{ color: "white" }}
            >
              <ViewListIcon />
            </IconButton>

            <IconButton style={{ color: "white" }}>
              <Badge badgeContent={appStore.countWishCgItems} color="secondary">
                <FavoriteIcon
                  onClick={(e) => history.push("/inventory/wishlist")}
                />
              </Badge>
            </IconButton>

            <IconButton style={{ color: "white" }}>
              <Badge
                badgeContent={appStore.countCommitCgItems}
                color="secondary"
              >
                <ShoppingBasketIcon
                  onClick={(e) => history.push("/inventory/commitlist")}
                />
              </Badge>
            </IconButton>

            <IconButton style={{ color: "white" }} onClick={(e) => signOut()}>
              <ExitToAppIcon />
            </IconButton>
          </Toolbar>
        </Container>
      </AppBar>

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={menu}
        classes={{ paper: classes.drawerPaper }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={(e) => toggleMenu(false)}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem button key={"Home"} onClick={(e) => history.push("/")}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={"Home"} />
          </ListItem>
          <ListItem
            button
            key={"Explore Inventory"}
            onClick={(e) => menuNavigate("/inventory")}
          >
            <ListItemIcon>
              <ViewListIcon />
            </ListItemIcon>
            <ListItemText primary={"Furniture List"} />
          </ListItem>
          <ListItem
            button
            key={"Wish List"}
            onClick={(e) => menuNavigate("/inventory/wishlist")}
          >
            <ListItemIcon>
              <Badge badgeContent={appStore.countWishCgItems} color="secondary">
                <FavoriteIcon />
              </Badge>
            </ListItemIcon>
            <ListItemText primary={"Wish List"} />
          </ListItem>

          <ListItem
            button
            key={"Basket List"}
            onClick={(e) => menuNavigate("/inventory/commitlist")}
          >
            <ListItemIcon>
              <Badge
                badgeContent={appStore.countCommitCgItems}
                color="secondary"
              >
                <ShoppingBasketIcon />
              </Badge>
            </ListItemIcon>
            <ListItemText primary={"Basket List"} />
          </ListItem>

<Divider/>

          <ListItem button key={"FAQ"} onClick={(e) => history.push("/inventory/faq")}>
            <ListItemIcon>
              <HelpIcon />
            </ListItemIcon>
            <ListItemText primary={"FAQ"} />
          </ListItem>

        </List>
      </Drawer>

      <main
        className={clsx(
          classes.content,
          { [classes.contentShift]: menu },
          { width: "100%" }
        )}
      >
        <Container maxWidth="lg">
          <Toolbar id="back-to-top-anchor" />

          <Switch>
            <Route path="/inventory/commitlist">
              <div style={{ display: "flex" }}>
                <div style={{ flexGrow: 1 }}>
                  <Typography variant="h3" gutterBottom>
                    Basket List
                  </Typography>
                </div>
                <div>
                  <span class="hide">
                    <Typography display="inline" variant="h6"  className={
                      appStore.maxCeeGees < 0 ? classes.negativeCeeGees : ""
                    } gutterBottom>
                      Remaining&nbsp;
                    </Typography>
                  </span>
                  <Typography
                    display="inline"
                    variant="h6"
                    className={
                      appStore.maxCeeGees < 0 ? classes.negativeCeeGees : ""
                    }
                    gutterBottom
                  >
                    CeeGees: {appStore.maxCeeGees}
                  </Typography>
                </div>
              </div>
             <CGCommitList appStore={appStore} />
              
            </Route>

            <Route path="/inventory/wishlist">
              <div style={{ float: "left" }}>
                <Typography variant="h3" gutterBottom>
                  Wish List
                </Typography>
              </div>
              <div style={{ float: "right" }}>
                <span class="hide">
                  <Typography display="inline" variant="h6" className={
                      appStore.maxCeeGees < 0 ? classes.negativeCeeGees : ""
                    } gutterBottom>
                    Remaining&nbsp;
                  </Typography>
                </span>
                <Typography
                  display="inline"
                  variant="h6"
                  className={
                    appStore.maxCeeGees < 0 ? classes.negativeCeeGees : ""
                  }
                  gutterBottom
                >
                  CeeGees: {appStore.maxCeeGees}
                </Typography>
              </div>

              <CGWhishList appStore={appStore} />
            </Route>

            <Route path="/inventory/faq">

              <FAQ/>

            </Route>

            <Route path="/">
              <Typography variant="h3" gutterBottom>
                Furniture List
              </Typography>

              <Inventory appStore={appStore} />
            </Route>
          </Switch>
        </Container>
      </main>

      <ScrollTop>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </div>
  );
}

export default observer(Main);
