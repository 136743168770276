/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCGItem = /* GraphQL */ `
  query GetCGItem($id: ID!) {
    getCGItem(id: $id) {
      id
      imageName
      itemName
      price
      description
      metadata
      isAvailable
      group
      sorting
      luckyWinner
      cGItemBaskets {
        items {
          id
          cgItemId
          cgBasketId
          cGItem {
            id
            imageName
            itemName
            price
            description
            metadata
            isAvailable
            group
            sorting
            luckyWinner
            cGItemBaskets {
              nextToken
            }
            createdAt
            updatedAt
          }
          cGBasket {
            id
            owner
            cGItemBaskets {
              nextToken
            }
            createdAt
            updatedAt
          }
          state
          cgPoints
          odds
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCGItems = /* GraphQL */ `
  query ListCGItems(
    $filter: ModelCGItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCGItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        imageName
        itemName
        price
        description
        metadata
        isAvailable
        group
        sorting
        luckyWinner
        cGItemBaskets {
          items {
            id
            cgItemId
            cgBasketId
            cGItem {
              id
              imageName
              itemName
              price
              description
              metadata
              isAvailable
              group
              sorting
              luckyWinner
              createdAt
              updatedAt
            }
            cGBasket {
              id
              owner
              createdAt
              updatedAt
            }
            state
            cgPoints
            odds
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCGBasket = /* GraphQL */ `
  query GetCGBasket($id: ID!) {
    getCGBasket(id: $id) {
      id
      owner
      cGItemBaskets {
        items {
          id
          cgItemId
          cgBasketId
          cGItem {
            id
            imageName
            itemName
            price
            description
            metadata
            isAvailable
            group
            sorting
            luckyWinner
            cGItemBaskets {
              nextToken
            }
            createdAt
            updatedAt
          }
          cGBasket {
            id
            owner
            cGItemBaskets {
              nextToken
            }
            createdAt
            updatedAt
          }
          state
          cgPoints
          odds
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCGBaskets = /* GraphQL */ `
  query ListCGBaskets(
    $filter: ModelCGBasketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCGBaskets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        cGItemBaskets {
          items {
            id
            cgItemId
            cgBasketId
            cGItem {
              id
              imageName
              itemName
              price
              description
              metadata
              isAvailable
              group
              sorting
              luckyWinner
              createdAt
              updatedAt
            }
            cGBasket {
              id
              owner
              createdAt
              updatedAt
            }
            state
            cgPoints
            odds
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const cgItemsSorted = /* GraphQL */ `
  query CgItemsSorted(
    $group: Int
    $sorting: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCGItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cgItemsSorted(
      group: $group
      sorting: $sorting
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        imageName
        itemName
        price
        description
        metadata
        isAvailable
        group
        sorting
        luckyWinner
        cGItemBaskets {
          items {
            id
            cgItemId
            cgBasketId
            cGItem {
              id
              imageName
              itemName
              price
              description
              metadata
              isAvailable
              group
              sorting
              luckyWinner
              createdAt
              updatedAt
            }
            cGBasket {
              id
              owner
              createdAt
              updatedAt
            }
            state
            cgPoints
            odds
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
