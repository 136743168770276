import React, { useEffect, useState } from "react";

import { observer } from "mobx-react-lite";

import { Typography } from "@material-ui/core";

import './faq.css'

function FAQ({ appStore }) {

    return (
        <div>

            <Typography className="header" variant="h5" >Frequently Asked Questions</Typography>

            <div className="question" >
                <Typography variant="h6" >How do I connect to the App?</Typography>
                <Typography variant="p" >Click on <a href="https://www.hackomplishers.com">www.hackomplishers.com</a> to log in and reset. Please follow instructions on the Home Page. You can connect from your phone, tablet or laptop.</Typography>
            </div>

            <div className="question" >
                <Typography variant="h6" >I am a temporary associate, can I participate?</Typography>
                <Typography variant="p" >The furniture sale is only open to GVO associates.</Typography>
            </div>

            <div className="question" >
                <Typography variant="h6" >I found a Corbusier armchair I love. How can I buy it?</Typography>
                <Typography variant="p" >
				
				You set up your profile on <a href="https://www.hackomplishers.com">www.hackomplishers.com</a>. You will have a number of points: <b>CeeGees</b> that you can allocate to the items you are interested in purchasing. The more CeeGees you allocate to a piece, the highest your chance is to get it. Between the 8th and the 14th of December, you can view your winning probability and modify your CeeGees allocation at any time to increase your chances. The draw will take place on December 15 at 11am.
				
				</Typography>
            </div>

            <div className="question" >
                <Typography variant="h6" >I cannot attend the live draw in person. How would I know the results?</Typography>
                <Typography variant="p" >Results will be posted on December 15th on CG Web.</Typography>
            </div>

            <div className="question" >
                <Typography variant="h6" >Can I return items after I bought them?</Typography>
                <Typography variant="p" >All furniture items are second-hand. You buy them as they are. In the event your item is damaged between the sale and collection date, you will have the right to withdraw from the sale the damaged item.</Typography>
            </div>

            <div className="question" >
                <Typography variant="h6" >Payment and collection of purchased items:</Typography>
                <Typography variant="p" >Payment and furniture collection will take place from the 27th of January to 8th February 2023.</Typography>

            </div>

            <div className="question" >
                <Typography variant="h6" >Can we have items delivered at home or loaded in my car?</Typography>
                <Typography variant="p" >Furniture can be collected from the office between the 27th of January to 8th February 2023. Make sure to come with appropriate vehicle and necessary equipment to load items in your car or truck. Office Services associates will not be on-site and therefore will not be able to move and load items on your behalf.</Typography>
            </div>

            <div className="question" >
                <Typography variant="h6" >What are the key dates?</Typography>
				
				<Typography variant="p" style={{display:"block"}}><b>From November 29<sup>th</sup></b> – setting up your profile on the platform, you will be able to see all items on sale.</Typography>
				<Typography variant="p" style={{display:"block"}}><b>From November 30<sup>th</sup></b> – opening of the sale – select pieces you’d like to buy until December 14<sup>th</sup>.</Typography>
				<Typography variant="p" style={{display:"block"}}><b>On December 15<sup>th</sup></b> – live draw in the Maillart Room at 11.00am.</Typography>
				<Typography variant="p"  style={{display:"block"}}><b>From the 27<sup>th</sup> of January to 8<sup>th</sup> February</b> – collection of the furniture.</Typography>
			
            </div>


            <div className="question" >
                <Typography variant="h6" >Who are the key contacts for questions?</Typography>
                <Typography variant="p" >
				For questions on logistics and furniture: Contact GVO OS on ext. 7566
				<br/>
				For technical questions on the app: Contact FTTA, SVD, JALB, JB
				<br/>
				For questions on payment: Contact BBG ext. 74321
				</Typography>

            </div>
			
        </div >
    );
}

export default observer(FAQ);
