import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import MobileStepper from "@material-ui/core/MobileStepper";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

import Dialog from "@material-ui/core/Dialog";

import Slide from "@material-ui/core/Slide";

import AppBar from "@material-ui/core/AppBar";

import Toolbar from "@material-ui/core/Toolbar";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Fab from "@material-ui/core/Fab";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { useSwipeable } from "react-swipeable";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  itemContainer: {
    marginBottom: "1rem",
    width: "100%",
  },

  item: {
    margin: "0.5rem",
    width: "94%",
    position: "relative",
  },

  itemContent: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.default,
    backgroundColor: "white !important",
  },

  itemImgContainer: {
    width: "100%",
    overflow: "hidden",
  },

  itemImg: {
    marginTop: "6px",
    height: "auto",
    width: "100%",
  },

  navigation: {
    backgroundColor: "rgba(240, 240, 240, .3)",
    position: "absolute",
    left: "0px",
    top: "0px",
    zIndex: "1000",
    width: "100%",
    color: "white !important",
    fontWeight: "bold",
  },

  navigationButton: {
    color: "white !important",
    fontWeight: "bold",
  },

  dialogImage: {
    width: "100%",
  },

  dialogNavigation: {
    backgroundColor: "rgba(250, 250, 250, 0)",
    color: "white !important",
    fontWeight: "bold",
  },

  dialogAppBar: {
    top: "auto",
    bottom: 0,
  },

  dialogImageContainer: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  dialogCloseButton: {
    position: "absolute",
    right: theme.spacing(4),
    top: theme.spacing(2),
    position: "fixed",
  },

  dialogZoomButton: {
    position: "absolute",
    right: theme.spacing(11),
    top: theme.spacing(2),
    position: "fixed",
  },

  textContent: {
    whiteSpace: "pre-line"
  },

  price: {
    marginBottom: theme.spacing(1),
    fontWeight: "bold"
  }
}));

function CGItem(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [maxSteps, setMaxSteps] = React.useState(0);

  const [dialogShow, setDialogShow] = React.useState(false);
  const [dialogImageZoom, setDialogImageZoom] = React.useState(false);

  const handleImageClickOpen = () => {
    setDialogShow(true);
  };

  const handleImageClickClose = () => {
    setDialogShow(false);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => activeStep < maxSteps - 1 && handleNext(),
    onSwipedRight: () => activeStep > 0 && handleBack(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  React.useEffect(() => {
    setMaxSteps(
      props.imageName
        ? props.imageName.filter((image) => image.includes("_tn.jpg")).length
        : 0
    );
  });

  return (
    <div>
      <Paper className={classes.itemContainer} elevation={3}>
        <div className={classes.item}>
          <MobileStepper
            className={classes.navigation}
            steps={maxSteps}
            position="static"
            variant="text"
            activeStep={activeStep}
            nextButton={
              <Button
                className={classes.navigationButton}
                size="small"
                onClick={handleNext}
                disabled={activeStep === maxSteps - 1}
              >
                Next <KeyboardArrowRight />
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
                className={classes.navigationButton}
              >
                <KeyboardArrowLeft /> Back
              </Button>
            }
          />

          <div className={classes.itemImgContainer}>
            <div {...handlers}>
              <LazyLoadImage
                onClick={handleImageClickOpen}
                className={classes.itemImg}
                src={
                  props.imageName.filter((image) => image.includes("_tn.jpg"))[
                    activeStep
                  ]
                } // use normal <img> attributes as props
              />
            </div>
          </div>
          <Paper square elevation={0} className={classes.itemContent}>
            <span>
              <Typography gutterBottom variant="h5" component="h4">
                {props.itemName}
              </Typography>

              <Typography className={classes.price} variant="body2" color="textSecondary" component="p">
                {props.price} CHF
              </Typography>
            </span>
            <Typography className={classes.textContent} variant="body2" color="textSecondary" component="p">
              {props.description}
            </Typography>
          </Paper>
          <div>{props.children}</div>
        </div>
      </Paper>

      <Dialog
        fullScreen
        open={dialogShow}
        onClose={handleImageClickClose}
        TransitionComponent={Transition}
      >
        <div>
          <div className={classes.dialogImageContainer}>
            <img
              className={dialogImageZoom ? "" : classes.dialogImage}
              src={
                props.imageName.filter((image) => !image.includes("_tn.jpg"))[
                  activeStep
                ]
              }
            />
          </div>
          <AppBar position="fixed" className={classes.dialogAppBar}>
            <MobileStepper
              className={classes.dialogNavigation}
              steps={maxSteps}
              position="static"
              variant="text"
              activeStep={activeStep}
              nextButton={
                <Button
                  className={classes.navigationButton}
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep === maxSteps - 1}
                >
                  Next <KeyboardArrowRight />
                </Button>
              }
              backButton={
                <Button
                  className={classes.navigationButton}
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  <KeyboardArrowLeft /> Back
                </Button>
              }
            />
          </AppBar>
          <Fab
            color="secondary"
            aria-label="zoom"
            size="small"
            className={classes.dialogZoomButton}
            onClick={() =>
              dialogImageZoom
                ? setDialogImageZoom(false)
                : setDialogImageZoom(true)
            }
          >
            {dialogImageZoom ? <ZoomOutIcon /> : <ZoomInIcon />}
          </Fab>

          <Fab
            color="secondary"
            aria-label="close"
            size="small"
            className={classes.dialogCloseButton}
            onClick={handleImageClickClose}
          >
            <CloseIcon />
          </Fab>
        </div>
      </Dialog>
    </div>
  );
}
export default CGItem;
