/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://aa7ubofzajgb7g67h7lvaigjma.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-west-1:312b4d01-2cf4-4246-930a-e3292430808f",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_ElJ5sM2Rw",
    "aws_user_pools_web_client_id": "5u3ctk232n1ebt5rjjb9ua2m9p",
    "oauth": {},
    "aws_user_files_s3_bucket": "reactteam-items205439-dev",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
