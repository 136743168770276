import { Hub } from "aws-amplify";
import {
  createCGBasket,
  createCGItemBasket,
  deleteCGItemBasket,
  updateCGItemBasket
} from "../graphql/mutations";
import { listCGBaskets, cgItemsSorted } from "../graphql/queries";
import { API, graphqlOperation, Auth, Storage } from "aws-amplify";

import { toJS } from "mobx";

import {
  makeObservable,
  runInAction,
  observable,
  computed,
  action,
} from "mobx";

class AppStore {
  cgBasket = null;

  cgItems = [];

  user = null;

  menu = true;

  isStoreInitialize = false;

  constructor() {
    makeObservable(this, {
      cgBasket: observable,
      cgItems: observable,
      user: observable,
      isStoreInitialize: observable,

      countWishCgItems: computed,
      countCommitCgItems: computed,
      getWishedCGItemBaskets: computed,
      getCommittedCGItemBaskets: computed,
      maxCeeGees: computed,

      deleteFromWishList: action,
      addToWishList: action,
      fetchCGItems: action,
      init: action,
      assignCeeGees: action,
      commitCGItemBasket: action,
      toggleMenu: action,
      backToWishList: action,

      destroy: action
    });
  }

  destroy() {
    this.cgBasket = null;
    this.cgItems = [];  
    this.user = null;
    this.menu = true;
    this.isStoreInitialize = false;
  }

  toggleMenu(state) {
    this.menu = state;  
  }

  get countWishCgItems() {
    return this._getCGItemBasketsByState("WISH");
  }

  get countCommitCgItems() {
    return this._getCGItemBasketsByState("COMMITTED");
  }

  get getCommittedCGItemBaskets() {
    return this._getCGItemBasketsBySate("COMMITTED");
  }

  get getWishedCGItemBaskets() {
    return this._getCGItemBasketsBySate("WISH");
  }

  _getCGItemBasketsBySate(state) {
    let result =  this.cgBasket.cGItemBaskets.items
    .filter((ib) => ib.state == state);

    return result;
  }

  _getCGItemBasketsByState(state) {
    if (this.cgBasket == null) return 0;

    let cGItemBaskets = this.cgBasket.cGItemBaskets.items.filter( (i) => i.state == state)
    return (cGItemBaskets == null ? 0 : cGItemBaskets.length);
  }

  async addToWishList(itemId) {
    let cgItemBasket = {
      id: itemId + " " + this.cgBasket.id,
      state: "WISH",
      cgItemId: itemId,
      cgBasketId: this.cgBasket.id,
    };

    let cgItemBasketData = await API.graphql(
      graphqlOperation(createCGItemBasket, { input: cgItemBasket })
    );

    cgItemBasket = cgItemBasketData.data.createCGItemBasket

    cgItemBasket.cGItem = await this.loadImage(cgItemBasket.cGItem);

    runInAction(() => this.cgBasket.cGItemBaskets.items.push(cgItemBasket));
  }

  async backToWishList(itemId) {
    let foundCgItemBasketIndex = this.cgBasket.cGItemBaskets.items.findIndex(
      (ib) => ib.cgItemId == itemId
    );

    let updateObject = this.cgBasket.cGItemBaskets.items[foundCgItemBasketIndex];

    let cgItemBasket = {
      id: updateObject.id,
      cgItemId: updateObject.cgItemId,
      cgBasketId: updateObject.cgBasketId,
      state: "WISH",
      cgPoints: 0,
      odds: 0,
    }

    cgItemBasket = await API.graphql(
      graphqlOperation(updateCGItemBasket, { input: cgItemBasket})
    );

    cgItemBasket = cgItemBasket.data.updateCGItemBasket;

    cgItemBasket.cGItem = await this.loadImage(cgItemBasket.cGItem);

    runInAction(() => this.cgBasket.cGItemBaskets.items[foundCgItemBasketIndex] = cgItemBasket);
  }

  async deleteFromWishList(itemId) {
    let foundCgItemIndex = this.cgBasket.cGItemBaskets.items.findIndex(
      (ib) => ib.cgItemId == itemId
    );

    await API.graphql(
      graphqlOperation(deleteCGItemBasket, {
        input: { id: this.cgBasket.cGItemBaskets.items[foundCgItemIndex].id },
      })
    );

    runInAction(() =>
      this.cgBasket.cGItemBaskets.items.splice(foundCgItemIndex, 1)
    );
  }

  async fetchCGItems() {
    let cgItemsData = await API.graphql(graphqlOperation(cgItemsSorted, {group: 1, sortDirection: 'ASC', limit: 200}));
    let cgItems = cgItemsData.data.cgItemsSorted.items;

    // load images for items
    cgItems = await Promise.all(
      cgItems.map(async (cgItem) => {
        return await this.loadImage(cgItem);
      })
    );

    runInAction(() => (this.cgItems = cgItems));
  }

  async init() {
    let user = await Auth.currentAuthenticatedUser({ bypassCache: false });
    
    // fetch basket
    let cgBasketsData = await API.graphql(
      graphqlOperation(listCGBaskets, {
        filter: { owner: { eq: user.username } },
      })
    );

    // if basket does not exist, create a new one
    let cgBasketTemp;
    if (
      cgBasketsData.data.listCGBaskets.items == null ||
      cgBasketsData.data.listCGBaskets.items.length == 0
    ) {
      let cgBasketData = await API.graphql(graphqlOperation(createCGBasket, {input: {owner: user.username}}));
      cgBasketTemp = cgBasketData.data.createCGBasket;
    } else {
      cgBasketTemp = cgBasketsData.data.listCGBaskets.items[0];
    }

    // load images for items in basket
    cgBasketTemp.cGItemBaskets.items = await Promise.all(
      cgBasketTemp.cGItemBaskets.items.map(async (ib) => {
        ib.cGItem = await this.loadImage(ib.cGItem);
        return ib;
      })
    );

    runInAction(() => {
      this.user = user;
      this.cgBasket = cgBasketTemp;
      this.isStoreInitialize = true;
    });
  }

  isCGItemWished(itemId) {
    return this.cgBasket.cGItemBaskets.items.find((ib) => ib.cgItemId == itemId && ib.state == "WISH") != null;
  }

  isCGItemCommitted(itemId) {
    return this.cgBasket.cGItemBaskets.items.find((ib) => ib.cgItemId == itemId && ib.state == "COMMITTED") != null;
  }

  getCeeGees(itemId) {
    return this.cgBasket.cGItemBaskets.items.find((ib) => ib.cgItemId == itemId).cgPoints
  }
 
  assignCeeGees(itemId, ceeGees) {
   let cGItemBasketIndex =  this.cgBasket.cGItemBaskets.items.findIndex((ib) => ib.cgItemId == itemId)
   let cGItemBasket = this.cgBasket.cGItemBaskets.items[cGItemBasketIndex];

   cGItemBasket.cgPoints = ceeGees;
   this.cgBasket.cGItemBaskets.items[cGItemBasketIndex] = cGItemBasket
  }

  async commitCGItemBasket(cGItemBasket) {
    cGItemBasket = await API.graphql(graphqlOperation(updateCGItemBasket, {input: {id:cGItemBasket.id, state:"COMMITTED", cgPoints:cGItemBasket.cgPoints}}));
    cGItemBasket = cGItemBasket.data.updateCGItemBasket;

    cGItemBasket.cGItem = await this.loadImage(cGItemBasket.cGItem);

    let foundIndex = this.cgBasket.cGItemBaskets.items.findIndex(ib=>ib.cgItemId == cGItemBasket.cgItemId)
    runInAction(() => {
     this.cgBasket.cGItemBaskets.items[foundIndex] = cGItemBasket;
    });
  }

    get maxCeeGees() {
        if(this.cgBasket == null || this.cgBasket.cGItemBaskets == null ) return 0;

        const reducer = (accumulator, currentValue) => {
         return accumulator + (currentValue.cgPoints == undefined ? 0 : currentValue.cgPoints);
        }
        let result = this.cgBasket.cGItemBaskets.items.reduce(reducer, 0);
        return 100 - result;
    }

    get maxCeeGeesWished() {
      if(this.cgBasket == null || this.cgBasket.cGItemBaskets == null ) return 0;

      const reducer = (accumulator, currentValue) => accumulator + currentValue.cgPoints;
      let result = this.cgBasket.cGItemBaskets.items.filter((ib) => ib.state == "WISH").reduce(reducer, 0);
      return result;
  }

  async loadImage(cgItem) {
    cgItem.imageName = await Promise.all(cgItem.imageName.map(async (image) => await Storage.get(image)));
    return cgItem;
  }
}

export const appStore = new AppStore();
