import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import Button from "@material-ui/core/Button";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import ViewListIcon from "@material-ui/icons/ViewList";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import ReactPlayer from "react-player";
import Dialog from "@material-ui/core/Dialog";
import { Storage } from "aws-amplify";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import Modal from '@material-ui/core/Modal';
import appleShare from "./AppleShare.png";
import logo from "../logo.svg";

import "./landing.css";
import "./bootstrap/css/bootstrap-scope.css";
import { Typography } from "@material-ui/core";

const isInStandaloneMode = () =>
      (window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone) || document.referrer.includes('android-app://');

const checkForIOS = () => {
  if (navigator.standalone) {
    return false;
  }

  const ua = window.navigator.userAgent;
  const webkit = !!ua.match(/WebKit/i);
  const isIPad = !!ua.match(/iPad/i);
  const isIPhone = !!ua.match(/iPhone/i)
  const isIOS = isIPad || isIPhone;
  const isSafari = isIOS && webkit && !ua.match(/CriOS/i);

  return isIOS && isSafari;
}

function Landing({ appStore }) {
  const [video, setVideo] = useState(false);
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [videoPlay, setVideoPlay] = useState(false);
  const [openIOs, setOpenIOs] = React.useState(false);
  const history = useHistory();

  useEffect(() => {
    getTutorialUrl();

    const handler = (e) => {
      e.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("transitionend", handler);
  }, []);

  const addToHomeScreen = (e) => {
    e.preventDefault();

    if (checkForIOS()) {
      setOpenIOs(true);
    } else {
      if (!promptInstall) {
        return;
      }
      promptInstall.prompt();
    }
  };

  async function getTutorialUrl() {
    setVideoUrl(await Storage.get("IMG_2367.MOV"));
  }

  return (
    <div className="bootstrap-scope landing">
      <section className="section section-lg cg" id="home">
        <div className="container h100 vauto">
          <div className="row h100 align-items-center">
            <div className="text-white text-center col-lg-8 offset-lg-2">
              <h1 className="home-title text-rotate">
                GVO Luxury Furniture Sale
              </h1>
              <p className="pt-3 home-desc">
                Office Services bring you the opportunity to buy some
                amazing items of furniture with massive discounts. As these
                items are in huge demand, you have to decide what you would
                like to buy, then try to win the items. If you are lucky (or
                skillful!) to win the wheel of fortune, you are committed to
                buying the item. You can then take it home and enjoy it!
              </p>
              <p
                className="play-shadow  mt-4"
                onClick={(e) => {
                  setVideo(true);
                  setVideoPlay(true);
                }}
              >
                <a className="play-btn video-play-icon">
                  <PlayArrowIcon className="icon text-center"></PlayArrowIcon>
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="section section-lg " id="inventory">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1 className="section-title text-center">Take a look:</h1>
              <div className="section-title-border mt-3"></div>
              <p className="section-subtitle text-muted text-center pt-4 font-secondary">
                Please browse the items that are available and select what you
                like. All items are high quality goods, in a nearly new
                condition, having one careful previous owner!
              </p>
            </div>
          </div>
          <div className="mt-4">
            <div className="mt-3 inventory">
              <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={() => history.push("/inventory")}
              >
                Explore Inventory
              </Button>

              <Button
                className="pwa"
                variant="outlined"
                size="medium"
                color="primary"
                onClick={addToHomeScreen}
                style={{display:isInStandaloneMode() ? "none" : "block"}}
              >
                Add to Home Screen
              </Button>

              <Modal open={openIOs} onClose={e => setOpenIOs(false)} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                <div className="iosmodal" >
                  <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "1rem" }}>
                    <img
                      src={logo}
                      height="72"
                      width="72"
                      alt="Install PWA"
                    />

                    <Typography variant="h4" className="text-center">Install Hackomplishers!</Typography>

                    <p className="pt-3 text-muted text-center">Install this application on your homescreen for a better experience.</p>

                    <div >
                      <p className="pt-3 text-muted text-center">
                        Tap
                        <img
                          src={appleShare}
                          style={{ margin: "auto 4px", display: "inline-block" }}
                          alt="Add to homescreen"
                          height="20"
                          width="20"
                        />
                        then &quot;Add to Home Screen&quot;
                      </p>
                    </div>
                  </div>

                </div>
              </Modal>
            </div>
          </div>
        </div>
      </section>

      <section className="section bg-light" id="services">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1 className="section-title text-center">How To Get Started:</h1>
              <div className="section-title-border mt-3"></div>
            </div>
          </div>
          <div className="mt-4 row">
            <div className="mt-3 col-lg-3">
              <div className="services-box text-center hover-effect">
                <div className="icon-container">
                  <ViewListIcon className="icon" onClick={e => history.push("/inventory")} />
                </div>
                <h4 className="pt-3">1. Explore Inventory</h4>
                <p className="pt-3 text-muted">
                  Have a look at the list of available items and decide what you
                  like
                </p>
              </div>
            </div>
            <div className="mt-3 col-lg-3">
              <div className="services-box text-center hover-effect">
                <div className="icon-container">
                  <FavoriteIcon className="icon" onClick={e => history.push("/inventory/wishlist")} />
                </div>
                <h4 className="pt-3">2. Add to Wish List</h4>
                <p className="pt-3 text-muted">
                  Add each item you would like to buy to your personal Wish List
                </p>
              </div>
            </div>
            <div className="mt-3 col-lg-3">
              <div className="services-box text-center hover-effect">
                <div className="icon-container">
                  <ShoppingBasketIcon className="icon" onClick={e => history.push("/inventory/wishlist")} />
                </div>
                <h4 className="pt-3">3. Add CeeGees</h4>
                <p className="pt-3 text-muted">
                  Place tokens (called CeeGees) to increase your chance of
                  winning your favourite items
                </p>
              </div>
            </div>
            <div className="mt-3 col-lg-3">
              <div className="services-box text-center hover-effect">
                <div className="icon-container">
                  <AccountBalanceWalletIcon className="icon" onClick={e => history.push("/inventory/commitlist")} />
                </div>
                <h4 className="pt-3">4. Purchase</h4>
                <p className="pt-3 text-muted">
                  If you win you are committed to purchasing the item, simply
                  pay and then take it away
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section" id="about" >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1 className="section-title text-center">About us</h1>
              <div className="section-title-border mt-3"></div>
            </div>
          </div>
          <div className="mt-4 row">
            <div className="mt-3 col-lg-12">
              <div className="services-box text-center hover-effect">
                <h4 className="pt-3">Our Story</h4>
                <p className="pt-3 text-muted">
                  As we all look forward to the unveiling of the new office in Geneva, the existing office needs to be cleared. Office Services (OS) are generously giving an opportunity for associates to purchase some of the designer furniture items at a massively discounted price.
                  “How can I take home some of this amazing furniture?” we hear you ask. This is a popular question and so OS approached the IT team for help to come up with ideas to decide how the items can be purchased (and by who). To find a fun approach for associates, the IT group ran a Hackathon …
                </p>
              </div>
            </div>
            <div className="mt-3 col-lg-12">
              <div className="services-box text-center hover-effect">
                <h4 className="pt-3">What is a Hackathon?</h4>
                <p className="pt-3 text-muted">
                  Our Hackathon involved 3 teams who took part in a competition to design and create a fun, furniture sale experience. We had a very tough judging panel to pitch our ideas to, rumour has it that Simon Cowell will be recruiting them for the next X-factor series!   The teams all created different ideas, finally working together to bring you the final concept by team “Hackomplishers”, hence the website name!
                </p>
              </div>
            </div>
            <div className="mt-3 col-lg-12">
              <div className="services-box text-center hover-effect">
                <h4 className="pt-3">What is the schedule?</h4>
                <p className="pt-3 text-muted">
                  On November 30<sup>th</sup> the sale will officially be open. Please feel free to look at the items for sale and place some orders – you will get daily updates on each item to show you how popular they are and your chances of winning. You are welcome to change your mind or modify your orders at any time until the sale close on December 14<sup>th</sup> when the portal will be locked.
                  We kindly ask that all purchased items are paid for and collected between January 27<sup>th</sup> and February 8<sup>th</sup> under the supervision of OS.
                </p>
              </div>
            </div>
            <div className="mt-3 col-lg-12">
              <div className="services-box text-center hover-effect">
                <h4 className="pt-3">What will the outcome be?</h4>
                <p className="pt-3 text-muted">
                  Our intent is to show some of the capabilities that new technology offers as well as the creative skills of the IT team. We have used modern tools and languages (including Amazon Web Services) to create a solution that looks a little more like your favourite shopping sites than the usual excel sheets that have traumatised us.  We just hope that all who join in and use the website have as much fun as we did creating it.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section bg-light" id="services">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1 className="section-title text-center">Contact Us</h1>
              <div className="section-title-border mt-3"></div>
            </div>
          </div>
          <div className="mt-4 row">
            <div className="mt-3 col-lg-12">
              <div className="services-box text-center hover-effect">
                <p className="pt-3 text-muted">
                  If you have any questions contact GVO office services.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Dialog
        className="landing"
        fullWidth={true}
        maxWidth="md"
        onClose={(e) => setVideo(false)}
        open={video}
        fullScreen
      >
        <AppBar>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={(e) => setVideo(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        {videoUrl && (
          <ReactPlayer
            playing={videoPlay}
            width="100%"
            height="100%"
            url={videoUrl}
          />
        )}
      </Dialog>
    </div>
  );
}

export default observer(Landing);
