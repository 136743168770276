import React from "react";
import Grid from "@material-ui/core/Grid";
import { observer } from "mobx-react-lite";
import CommitListCGItem from "./commitlist-item";

import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Timer from "./Timer";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import SnackbarContent from "@material-ui/core/SnackbarContent";

const useStyles = makeStyles((theme) => ({
  right: {
    float: "right",
  },

  commitMargin: {
    margin: theme.spacing(1),
  },

  divider: {
    marginTop: theme.spacing(2),
  },

  counter: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: "#019ade !important",
  },
}));

function CGCommitList({ appStore }) {
  const classes = useStyles();

  const startDate = "2022-12-15T11:00:00";

  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <div>
      <SnackbarContent
        className={classes.counter}
        message={[
          <Typography key={1} className={classes.counter}>
            Your odds of winning the right to purchase an item will be updated
            daily at midnight GVO time. You commit to purchasing any item below
            for which one of your assigned CeeGees has been picked during the
            lottery draw. The draw which will begin in:
          </Typography>,
      <h3>    <Timer key={2} startDate={startDate} />  </h3>   ,
        ]}
      />

      <Grid container spacing={3}>
        {appStore.getCommittedCGItemBaskets
          .map((ib) => ib.cGItem)
          .map((item, index) => (
            <Grid item md={12} xs={12} key={item.id}>
              <CommitListCGItem item={item} appStore={appStore} />
            </Grid>
          ))}
      </Grid>
    </div>
  );
}
export default observer(CGCommitList);
