/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCGItem = /* GraphQL */ `
  mutation CreateCGItem(
    $input: CreateCGItemInput!
    $condition: ModelCGItemConditionInput
  ) {
    createCGItem(input: $input, condition: $condition) {
      id
      imageName
      itemName
      price
      description
      metadata
      isAvailable
      group
      sorting
      luckyWinner
      cGItemBaskets {
        items {
          id
          cgItemId
          cgBasketId
          cGItem {
            id
            imageName
            itemName
            price
            description
            metadata
            isAvailable
            group
            sorting
            luckyWinner
            cGItemBaskets {
              nextToken
            }
            createdAt
            updatedAt
          }
          cGBasket {
            id
            owner
            cGItemBaskets {
              nextToken
            }
            createdAt
            updatedAt
          }
          state
          cgPoints
          odds
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCGItem = /* GraphQL */ `
  mutation UpdateCGItem(
    $input: UpdateCGItemInput!
    $condition: ModelCGItemConditionInput
  ) {
    updateCGItem(input: $input, condition: $condition) {
      id
      imageName
      itemName
      price
      description
      metadata
      isAvailable
      group
      sorting
      luckyWinner
      cGItemBaskets {
        items {
          id
          cgItemId
          cgBasketId
          cGItem {
            id
            imageName
            itemName
            price
            description
            metadata
            isAvailable
            group
            sorting
            luckyWinner
            cGItemBaskets {
              nextToken
            }
            createdAt
            updatedAt
          }
          cGBasket {
            id
            owner
            cGItemBaskets {
              nextToken
            }
            createdAt
            updatedAt
          }
          state
          cgPoints
          odds
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCGItem = /* GraphQL */ `
  mutation DeleteCGItem(
    $input: DeleteCGItemInput!
    $condition: ModelCGItemConditionInput
  ) {
    deleteCGItem(input: $input, condition: $condition) {
      id
      imageName
      itemName
      price
      description
      metadata
      isAvailable
      group
      sorting
      luckyWinner
      cGItemBaskets {
        items {
          id
          cgItemId
          cgBasketId
          cGItem {
            id
            imageName
            itemName
            price
            description
            metadata
            isAvailable
            group
            sorting
            luckyWinner
            cGItemBaskets {
              nextToken
            }
            createdAt
            updatedAt
          }
          cGBasket {
            id
            owner
            cGItemBaskets {
              nextToken
            }
            createdAt
            updatedAt
          }
          state
          cgPoints
          odds
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCGItemBasket = /* GraphQL */ `
  mutation CreateCGItemBasket(
    $input: CreateCGItemBasketInput!
    $condition: ModelCGItemBasketConditionInput
  ) {
    createCGItemBasket(input: $input, condition: $condition) {
      id
      cgItemId
      cgBasketId
      cGItem {
        id
        imageName
        itemName
        price
        description
        metadata
        isAvailable
        group
        sorting
        luckyWinner
        cGItemBaskets {
          items {
            id
            cgItemId
            cgBasketId
            cGItem {
              id
              imageName
              itemName
              price
              description
              metadata
              isAvailable
              group
              sorting
              luckyWinner
              createdAt
              updatedAt
            }
            cGBasket {
              id
              owner
              createdAt
              updatedAt
            }
            state
            cgPoints
            odds
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      cGBasket {
        id
        owner
        cGItemBaskets {
          items {
            id
            cgItemId
            cgBasketId
            cGItem {
              id
              imageName
              itemName
              price
              description
              metadata
              isAvailable
              group
              sorting
              luckyWinner
              createdAt
              updatedAt
            }
            cGBasket {
              id
              owner
              createdAt
              updatedAt
            }
            state
            cgPoints
            odds
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      state
      cgPoints
      odds
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateCGItemBasket = /* GraphQL */ `
  mutation UpdateCGItemBasket(
    $input: UpdateCGItemBasketInput!
    $condition: ModelCGItemBasketConditionInput
  ) {
    updateCGItemBasket(input: $input, condition: $condition) {
      id
      cgItemId
      cgBasketId
      cGItem {
        id
        imageName
        itemName
        price
        description
        metadata
        isAvailable
        group
        sorting
        luckyWinner
        cGItemBaskets {
          items {
            id
            cgItemId
            cgBasketId
            cGItem {
              id
              imageName
              itemName
              price
              description
              metadata
              isAvailable
              group
              sorting
              luckyWinner
              createdAt
              updatedAt
            }
            cGBasket {
              id
              owner
              createdAt
              updatedAt
            }
            state
            cgPoints
            odds
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      cGBasket {
        id
        owner
        cGItemBaskets {
          items {
            id
            cgItemId
            cgBasketId
            cGItem {
              id
              imageName
              itemName
              price
              description
              metadata
              isAvailable
              group
              sorting
              luckyWinner
              createdAt
              updatedAt
            }
            cGBasket {
              id
              owner
              createdAt
              updatedAt
            }
            state
            cgPoints
            odds
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      state
      cgPoints
      odds
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteCGItemBasket = /* GraphQL */ `
  mutation DeleteCGItemBasket(
    $input: DeleteCGItemBasketInput!
    $condition: ModelCGItemBasketConditionInput
  ) {
    deleteCGItemBasket(input: $input, condition: $condition) {
      id
      cgItemId
      cgBasketId
      cGItem {
        id
        imageName
        itemName
        price
        description
        metadata
        isAvailable
        group
        sorting
        luckyWinner
        cGItemBaskets {
          items {
            id
            cgItemId
            cgBasketId
            cGItem {
              id
              imageName
              itemName
              price
              description
              metadata
              isAvailable
              group
              sorting
              luckyWinner
              createdAt
              updatedAt
            }
            cGBasket {
              id
              owner
              createdAt
              updatedAt
            }
            state
            cgPoints
            odds
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      cGBasket {
        id
        owner
        cGItemBaskets {
          items {
            id
            cgItemId
            cgBasketId
            cGItem {
              id
              imageName
              itemName
              price
              description
              metadata
              isAvailable
              group
              sorting
              luckyWinner
              createdAt
              updatedAt
            }
            cGBasket {
              id
              owner
              createdAt
              updatedAt
            }
            state
            cgPoints
            odds
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      state
      cgPoints
      odds
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createCGBasket = /* GraphQL */ `
  mutation CreateCGBasket(
    $input: CreateCGBasketInput!
    $condition: ModelCGBasketConditionInput
  ) {
    createCGBasket(input: $input, condition: $condition) {
      id
      owner
      cGItemBaskets {
        items {
          id
          cgItemId
          cgBasketId
          cGItem {
            id
            imageName
            itemName
            price
            description
            metadata
            isAvailable
            group
            sorting
            luckyWinner
            cGItemBaskets {
              nextToken
            }
            createdAt
            updatedAt
          }
          cGBasket {
            id
            owner
            cGItemBaskets {
              nextToken
            }
            createdAt
            updatedAt
          }
          state
          cgPoints
          odds
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCGBasket = /* GraphQL */ `
  mutation UpdateCGBasket(
    $input: UpdateCGBasketInput!
    $condition: ModelCGBasketConditionInput
  ) {
    updateCGBasket(input: $input, condition: $condition) {
      id
      owner
      cGItemBaskets {
        items {
          id
          cgItemId
          cgBasketId
          cGItem {
            id
            imageName
            itemName
            price
            description
            metadata
            isAvailable
            group
            sorting
            luckyWinner
            cGItemBaskets {
              nextToken
            }
            createdAt
            updatedAt
          }
          cGBasket {
            id
            owner
            cGItemBaskets {
              nextToken
            }
            createdAt
            updatedAt
          }
          state
          cgPoints
          odds
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCGBasket = /* GraphQL */ `
  mutation DeleteCGBasket(
    $input: DeleteCGBasketInput!
    $condition: ModelCGBasketConditionInput
  ) {
    deleteCGBasket(input: $input, condition: $condition) {
      id
      owner
      cGItemBaskets {
        items {
          id
          cgItemId
          cgBasketId
          cGItem {
            id
            imageName
            itemName
            price
            description
            metadata
            isAvailable
            group
            sorting
            luckyWinner
            cGItemBaskets {
              nextToken
            }
            createdAt
            updatedAt
          }
          cGBasket {
            id
            owner
            cGItemBaskets {
              nextToken
            }
            createdAt
            updatedAt
          }
          state
          cgPoints
          odds
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
